import { t } from '@zupr/i18n'
import { Location } from '@zupr/types/fo'
import { ProductsAndAggregations, SiteProps } from '@zupr/types/next'
import { assortmentSchema } from '@zupr/utils/schema'
import { locationUrl } from '@zupr/utils/url'
import Frontoffice from '@zupr/web/src/js/frontoffice/components/base'
import Providers from '@zupr/web/src/js/frontoffice/providers'
import { LocationProducts } from '@zupr/web/src/js/frontoffice/screens/location'
import { GetServerSideProps } from 'next'

import { inShoppingArea, redirectToShoppingArea } from '..'
import Schema from '../../../../components/schema'
import { getLocation, locationMeta } from '../../../../helpers/location'
import { getProductsAndAggregations } from '../../../../helpers/products'
import { getProps } from '../../../../helpers/props'

interface PageProps extends SiteProps {
    location: Location
    productsAndAggregations: ProductsAndAggregations
    schema: Awaited<ReturnType<typeof assortmentSchema>>
}
export const getServerSideProps: GetServerSideProps<PageProps> = async ({
    req,
    params,
    query,
}) => {
    const siteProps = await getProps({ req, params, query })

    const location = await getLocation(
        { locationId: params.locationId as string, ...siteProps },
        req
    )

    if (!location) {
        return {
            notFound: true,
        }
    }

    if (!inShoppingArea(location, siteProps)) {
        return await redirectToShoppingArea(location, req)
    }

    const productsAndAggregations = await getProductsAndAggregations(
        {
            params: {
                'product_locations.location.id': location.id,
                limit: 24,
            },
            query: siteProps.query,
        },
        req
    )

    const meta = locationMeta({ location, ...siteProps })

    meta.title = `${t('Assortment')} | ${location.title}`

    // of location has no products skip to information page
    if (location.productsCount === 0) {
        return {
            redirect: {
                destination: locationUrl(location, 'information'),
                permanent: false,
            },
        }
    }

    const schema = await assortmentSchema({
        location,
        req,
        productsAndAggregations,
    })

    return {
        props: {
            ...siteProps,
            meta,
            location,
            productsAndAggregations,
            schema,
        },
    }
}

const Index = ({
    location,
    productsAndAggregations,
    schema,
    ...siteProps
}: PageProps) => (
    <Providers {...siteProps}>
        <Frontoffice>
            <LocationProducts
                location={location}
                {...productsAndAggregations}
            />
        </Frontoffice>
        <Schema schema={schema} id={location.id} />
    </Providers>
)

export default Index
